import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const WallpaperPolicy = () => (
    <Layout pageInfo={{ pageName: "Wallpaper-policy" }}>
        <SEO title="Wallpaper-policy" />
        <Container className="mt-3 mb-3">
            <h2>Privacy Policy</h2>
            <p> Aldo Pedro Rangel Montiel built the Simple Live Wallpaper Manager app as a Free app. This SERVICE is provided by
            Aldo Pedro Rangel Montiel at no cost and is intended for use as is without any warranty.
                  </p>
            <p>This page is used to inform visitors regarding my policies with the collection, use, and disclosure
            of Personal Information if anyone decided to use my Service.
                  </p>
            <p>This service <strong>does not</strong> store any personal information. It doesn't require any sort of
                  external connection nor transmits any data. Everything you customize on the service stays on your phone and
                  stays there unless you move it or transmit it yourself or allow a 3rd party to do so.
                  </p>
            <p><strong>Changes to This Privacy Policy</strong></p> <p> I may update our Privacy Policy from time to time. Thus, you are advised to review
            this page periodically for any changes. I will notify you of any changes by posting
            the new Privacy Policy on this page. These changes are effective immediately after they are posted on
            this page.
                  </p> <p><strong>Contact Us</strong></p> <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact
                    me at <a href="mailto:support@crimsonrgames.com?Subject=Question%20about%20privacy" target="_top">the support email</a>.
                  </p>

            <Link to="/">Go back to the homepage</Link>
        </Container>
    </Layout>
)

export default WallpaperPolicy
